<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field type="text"
                          filled
                          label="Назва"
                          v-model="name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12" class="d-flex">
            <v-textarea filled
                        label="Текст повідомлення"
                        auto-grow rows="3"
                        v-model="text"
                        hide-details
                        required :rules="[v => !!v || 'Це поле є обов’язковим']"
                        :class="text ? '' : 'req-star'"
                        style="flex: 1"
                        id="textarea"
                        color="grey"/>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class="grey lighten-4 ml-2"
                    style="flex: 0 0 38px; width: 338px"
                >
                  <v-icon color="success">
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <v-list nav dense style="height: 400px; overflow: auto">
                <v-list-item
                    v-for="(item, index) in params_table"
                    :key="index"
                    dense
                    @click="onParamClick(item)"
                >
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  CREATE_SMS_SEND_TEMPLATE, UPDATE_SMS_SEND_TEMPLATE, DELETE_SMS_SEND_TEMPLATE
} from "@/store/actions/sms_send_template";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'sms_send_template_modal_delete'

export default {
  name: "HWP_Modal_SmsSendTemplate",
  mixins: [ModalComponentMixin],
  data() {
    return {
      name: this.item.name,
      text: this.item.text,
      params_table: [
        { text: 'Повна адреса', value: '{full_address}' },
        { text: 'Адреса', value: '{address}' },
        { text: 'Власник ПІБ', value: '{flat_owner_full}' },
        { text: 'Власник ініціали', value: '{flat_owner_short}' },
        { text: 'Сальдо на поч.', value: '{start_balance}' },
        { text: 'Сальдо на поч. з урахуванням оплати', value: '{start_balance_minus_pay}' },
        { text: 'Сальдо на кін.', value: '{end_balance}' },
        { text: 'Оплачено', value: '{pay}' },
        { text: 'Нараховано', value: '{charge}' },
        { text: 'Об’єм', value: '{square}' },
        { text: 'Організація (скорочено)', value: '{organization_short_name}' },
        { text: 'Організація (повністю)', value: '{organization_full_name}' },
        { text: 'Код ЄДРПОУ', value: '{organization_code}' },
        { text: 'Ідентифікаційний код', value: '{organization_ipn}' },
        { text: 'Адреса орг. (юридична)', value: '{organization_address_yur}' },
        { text: 'Адреса орг. (фактична)', value: '{organization_address_fact}' },
        { text: 'Реквізити організації', value: '{organization_details_with_account}' },
        { text: 'Реквізити організації без банк.', value: '{organization_details_without_account}' },
        { text: 'Реквізити організації без контактів', value: '{organization_details_with_account_without_contacts}' },
        { text: 'Банківські реквізити', value: '{organization_bank_account_data}' },
        { text: 'Директор ПІБ', value: '{organization_director_full_name}' },
        { text: 'Директор ініціали', value: '{organization_director_short_name}' },
        { text: 'Директор посада', value: '{organization_director_position}' },
        { text: 'Директор ідент. код', value: '{organization_director_ipn}' },
        { text: 'Головний бухгалтер ПІБ', value: '{organization_chief_accounting_full_name}' },
        { text: 'Головний бухгалтер ініціали', value: '{organization_chief_accounting_short_name}' },
        { text: 'Головний бухгалтер посада', value: '{organization_chief_accounting_position}' },
        { text: 'Головний бухгалтер ідент. код', value: '{organization_chief_accounting_ipn}' },
      ]
    }
  },
  methods: {
    onParamClick(payload) {
      let textarea = document.getElementById("textarea");
      let start_position = textarea.selectionStart;
      let end_position = textarea.selectionEnd;

      this.text = `${textarea.value.substring(
          0,
          start_position
      )}${payload.value}${textarea.value.substring(
          end_position,
          textarea.value.length
      )}`
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.name = this.item.name
      this.text = this.item.text

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: 'Підтвердіть вилучення шаблону для смс сервісу',
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Назва має бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        name: this.name,
        text: this.text,
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_SMS_SEND_TEMPLATE, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_SMS_SEND_TEMPLATE, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.name = payload.name
              this.text = payload.text
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_SMS_SEND_TEMPLATE, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
